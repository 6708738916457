<template>
  <div class="copy-right border-top footer-nw">
    <!-- <p v-if="$auth && $auth.setting && $auth.setting.show_contact_developer == 'Yes' ">      
      <span>Powered by <u><a target="_blank" class="primary-link" href=""> {{$auth.getAppName()}}</a></u></span>
    </p> -->
    <div class="container">
      <div class="row">
        <div class="col-md-10">
          <!-- <div class="d-flex flex-column justify-content-between h-100">
              <div class="footernw-logo">
                <img :src="$auth.getAppLogo()" alt="foot-logo">
                <p><span class="d-flex justify-content-center gap-1 align-items-center">Powered by <ul><a target="_blank" class="primary-link text-decoration-underline " href="https://deonde.co/">DEONDE</a></ul></span></p>                
              </div>
              <div class="footernw-store">
                <a v-if="$auth.setting && $auth.setting.ios_customer_app_url" :href="$auth.setting.ios_customer_app_url"><img src="@/assets/images/app-store.png" alt="app-store" /></a>
                <a v-if="$auth.setting && $auth.setting.android_customer_app_url" :href="$auth.setting.android_customer_app_url"><img src="@/assets/images/google-play.png" alt="android-store" /></a>
              </div>
            </div> -->
          <p>
            © Copyright {{ new Date().getFullYear() }} | Gerecht aan Huis |&nbsp;Alle
            Rechten onder voorbehoud&nbsp;|&nbsp;Design &amp; technische realisatie:
            <a href="https://www.m2idee.nl" rel="noreferrer" target="_blank">M2 !dee</a>
          </p>
        </div>
        <div class="col-md-2">
          <div class="footernw-left d-flex flex-flow-row gap-3 justify-content-end">
            <a :href="'//' + facebookLink && facebookLink != '' ? '#' : facebookLink"
              ><i class="fab fa-facebook-f"></i
            ></a>
            <a :href="'//' + twitterLink"><i class="fab fa-twitter"></i></a>
            <a :href="'//' + instagramLink"><i class="fab fa-instagram"></i></a>
            <!-- <a v-if="facebookLink" :href="'//' + facebookLink">{{ $t('Facebook') }}</a>
              <a v-if="twitterLink" :href="'//' + twitterLink">{{ $t('Twitter') }}</a>
              <a v-if="instagramLink" :href="'//' + instagramLink">{{ $t('Instagram') }}</a> -->
          </div>
        </div>
        <!-- <div class="col-md-12 footer-bottom" v-if="$auth && $auth.setting && $auth.setting.show_contact_developer == 'Yes'">
            <div class="footer-bottom-inner">
              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="footerother-links">
                    <p><span class="d-flex justify-content-center gap-1 align-items-center">Powered by <ul><a target="_blank" class="primary-link text-decoration-underline " href="https://deonde.co/">DEONDE</a></ul></span></p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
      </div>
    </div>
    <cookie-law theme="dark-lime">
      <div slot="message">
        {{ $t("This") }} {{ $auth.getAppName() }}
        {{
          $t(
            "uses cookies to provide you with a great user experience. Please accept cookies for excellent optimal performance"
          )
        }}.
      </div>
    </cookie-law>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  name: "footerpage",
  components: { CookieLaw },
};
</script>
