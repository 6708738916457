<template>
  <div>
    <nav-bar></nav-bar>
    <section class="resturant-main tab-content header-top categorylist">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <home-title :title="categoryName" subtitle="Discover Unique Taste around you.">
              <filter-sort v-on:getFilterData="filterData"></filter-sort>
            </home-title>
            <!---------Restaurant Skeleton list Start-------->            
            <div class="hme-res-itm" v-if="is_skeleton_loadr">
              <div class="filter-title justify-content-between align-items-center mb-4">
                <h4><skeleton-loader-vue type="string" width="24%" height="20px" style="bottom: 11px" /></h4>
                <p class="mb-0"><skeleton-loader-vue type="string" width="30%" height="20px" /></p>
              </div>
              <div id="resturant-item-main" class="resturant-item-main">
                <a href="javascript:void(0)" v-for="itemcount in 4" :key="itemcount">
                  <div class="resturant-item d-flex align-items-start">
                    <skeleton-loader-vue width="120" class="img-cls" style="border-radius: 5px" />
                    <div class="resturant-data ms-3">
                      <h4><skeleton-loader-vue type="string" height="20" /></h4>
                      <span><skeleton-loader-vue type="string" width="80%" height="20px" /></span>
                      <div class="res-price-main mt-2 d-flex">
                        <div>
                          <skeleton-loader-vue type="string" width="44px" height="24px" />
                        </div>
                        <ul class="time-price d-flex justify-content-center mb-0 ms-3">
                          <li><skeleton-loader-vue type="string" width="44px" height="24px" /></li>
                          <li><span></span><skeleton-loader-vue type="string" width="100px" height="24px" /></li>
                        </ul>
                      </div>
                      <div class="offer-sec">
                        <p class="mb-0 mt-1"></p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <!---------Restaurant Skeleton list End-------->
            <div :class="'resturant-item-main' + (webVendorListingLayout == '1' ? '' : webVendorListingLayout)"
              id="resturant-item-main" v-if="items && items.length > 0 && !is_skeleton_loadr">
              <item v-for="item in items" :key="item.restaurant_id" :item="item"
                :currencys="$auth.setting && $auth.setting.currency" :isPickupDelivery="isPickupDelivery"
                :isSchedule="$auth.setting.is_enable_schedule_order_after_business_hours"
                :webVendorLayout="webVendorListingLayout"
                components=""></item>
            </div>
            <div v-if="items && items.length == 0 && !is_skeleton_loadr" class="order-offers rest-not-found">
              <div class="no-contnt-cls">
                <div class="nostr-img-cls">
                  <img src="@/assets/images/no_store.png" />
                  <div class="no-fnd-cls">{{ $t('No Found Data', { msg: categoryName }) }} </div>
                </div>
              </div>
            </div>
            <div id="touchFooter"></div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeTitle from "@/components/HomeTitle.vue";
import NavBar from "@/components/NavBar.vue";
import Item from "@/components/Item.vue";
import Footer from '../components/Footer.vue';
import FilterSort from "@/components/FilterSort.vue";
import { mapActions, mapGetters } from "vuex";
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: "CategoryList",
  components: {HomeTitle,NavBar,Item,FilterSort,Footer,'skeleton-loader-vue': VueSkeletonLoader},
  data() {
    return {
      items: [],
      currencys: "",
      loading: true,
      setting: {},
      filter: {
        lat: "",
        lng: "",
        city: "",
        state: ""        
      },
      skip: 0,
      isStopCallingApi: false,
      categID: 0,
      categoryName: '',
      is_skeleton_loadr: true,
      webVendorListingLayout: 1
    };
  },
  computed: {
    ...mapGetters("order", ["isPickupDelivery"])
  },

  mounted() {
    setTimeout(() => {
      this.webVendorListingLayout = this.$auth.setting && this.$auth.setting.vendor_listing_layout;
      if (this.webVendorListingLayout == '') {
        this.webVendorListingLayout = 1;
      }
    }, 500);
    const containerEl = document.getElementById('touchFooter');
    const io = new IntersectionObserver(([{ isIntersecting }]) => {
      if (isIntersecting) {
        if (!this.isStopCallingApi) {
          this.getRestaurant();
        }
      }
    });
    io.observe(containerEl);
  },

  methods: {
    ...mapActions("restaurant", ["getRestaurantBanner", "getRestaurants"]),

    filterData(cuisine) {
      this.sort = cuisine.sort;
      this.skip = 0;
      this.items = [];
      if (cuisine.cusinesIds) {
        this.cuisine_id = cuisine.cusinesIds.join();
      } else {
        this.cuisine_id = "";
      }
      this.filter.lat = parseFloat(localStorage.getItem("latitude"));
      this.filter.lng = parseFloat(localStorage.getItem("longitude"));
      this.getRestaurant();
      this.isStopCallingApi = false;
    },

    //Get All banner Restaurant
    getRestaurant() {
      let urlParmam = this.base64_decode(this.$route.params.category_id);
      let categoryData = urlParmam.split('/');
      if (categoryData && categoryData.length == 2) {
        this.categID = categoryData[0];
        this.categoryName = categoryData[1];
        this.loading = true;
        let restData = {
          date: "",
          category_id: this.categID,
          cityname: this.filter.city,
          cuisine_id: this.cuisine_id,
          skip: this.skip,
          starttime: "",
          is_langauge: this.$store.state.lang.locale,
          sort_type: this.sort,
          user_id: "",
          filter_type: "",
          cost_range: "",
          vendor_id: this.$auth.getVendorId(),
          state: this.filter.state,
          delivery_type_time_slots: this.isPickupDelivery,
          latitude: parseFloat(localStorage.getItem("latitude")),
          longitude: parseFloat(localStorage.getItem("longitude")),
        };
        this.getRestaurants(restData).then((data) => {
          this.loading = true;
          if (data.code == 200) {
            this.loading = false;
            this.skip = data.open_restaurant;
            if (!this.items.length) {
              data.Result.forEach((resItem) => {
                this.items.push(resItem);
              });
            } else {
              data.Result.forEach((resItem) => {
                let checkRest = this.items.find((elm) => elm.restaurant_id == resItem.restaurant_id);
                if (!checkRest) {
                  this.items.push(resItem);
                }
              });
            }
            this.is_skeleton_loadr = false;
          } else if (data.code == 101) {
            this.loading = false;
            this.isStopCallingApi = true;
            this.is_skeleton_loadr = false;
          } else {
            if (data.Result.length == 0) {
              this.loading = false;
              this.isStopCallingApi = true;
              this.is_skeleton_loadr = false;
            }
          }
        });
      } else {
        this.items = [];
        this.loading = false;
      }
    },
  },
};
</script>